import axios from "axios"
const token =
  document.querySelector('[name=csrf-token]').content

axios.defaults.headers.common['X-CSRF-TOKEN'] = token
axios.defaults.headers.common["Accept"] = "application/json"
axios.defaults.headers.common["Content-Type"] = "application/json"

export const allCoupons = async () => {
  return axios.get('/discount_coupons.json')
}

export const saveCoupon = async (body) => {
  return axios.post('/discount_coupons', body)
}

export const updateCoupon = async (id, data) => {
  return axios.patch(`/discount_coupons/${id}`, data)
}