import axios from "axios"

export function uploadImage(file) {
  const formData = new FormData();
  formData.append("file", file);
  
  return axios.post("/images/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}