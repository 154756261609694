import React, { useEffect } from 'react'

import { Alert } from '@material-tailwind/react';
import { ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/24/solid';


export function AlertDismissible({ ...props }) {
  const [open, setOpen] = React.useState(true);

  return (
    <>
      <Alert open={open} onClose={() => setOpen(false)} {...props}>
        {props.message}
      </Alert>
    </>
  );
}

export default (Children) => function ({ flash = [], ...props }) {
  const colorAndIconForFlashType = (type) => {
    switch (type) {
      case 'notice':
        return { color: 'green', icon: <InformationCircleIcon /> }
      case 'alert':
        return {
          color: 'red', icon: <ExclamationCircleIcon />
        }
    }
  }

  return (
    <>
      <div className="flex flex-col gap-2 mx-3 mt-2">
        {
          flash.map((flash) => {
            const message = flash[1]
            const type = flash[0]
            console.log(message)
            console.log(type)
            const { color, icon } = colorAndIconForFlashType(type)
            return (
              <AlertDismissible icon={icon} color={color} message={message} />
            )
          })
        }
      </div>
      <Children {...props} />
    </>
  )

}
