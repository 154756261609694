import React, { useState } from "react";
// @material-tailwind/react
import {
  Typography,
  Card,
  CardHeader,
  CardBody,
  Button,
  Input
} from "@material-tailwind/react";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { uploadImage } from "../../service/upload-image.service";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import withFlashMessages from "../../components/withFlashMessages";

function PageForm({ chapterId, page }) {
  const [editorState, setEditorState] = useState(
    () => {
      return page ? EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(page.content)
        )
      ) : EditorState.createEmpty()
    },
  );

  const uploadCallback = (file, callback) => {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();

      reader.onloadend = async () => {
        const res = await uploadImage(file);
        resolve({ data: { link: res.data.url } });
      };
      reader.readAsDataURL(file);
    });
  };


  return (
    <section className="m-10 h-screen">
      <Card className="w-full">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none flex flex-wrap gap-4 justify-between mb-4"
        >
          <div>
            <Typography variant="h6" color="blue-gray">
              Nova página
            </Typography>
            <Button type="submit" form="content-form">Salvar</Button>
          </div>
        </CardHeader>
        <CardBody className="h-full">
          <form id="content-form" method={page ? "put" : "post"} action={page ? `/chapters/${chapterId}/pages/${page._id}` : `/chapters/${chapterId}/pages`}>
            <div className="mb-6">
              <Input
                size="lg"
                name="page[title]"
                variant="outlined"
                placeholder="Título"
                label="Título"
                className="w-full"
              />
            </div>
            <input type="hidden" value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} name="page[content]" />
            <Editor
              toolbar={{
                image: {
                  urlEnabled: false,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: uploadCallback,
                }
              }
              }
              wrapperClassName="h-full border border-gray-300"
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setEditorState}
            />
          </form>
        </CardBody>
      </Card>
    </section>
  );
}

export default withFlashMessages(PageForm);