import React, { useState } from "react";
// @material-tailwind/react
import {
  Typography,
  Card,
  CardHeader,
  CardBody,
  IconButton,
  Button
} from "@material-tailwind/react";

import {
  PencilIcon,
  PlusIcon,
  TicketIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import container from "./container";
import DeleteModal from "../../components/DeleteModal";
import withFlashMessages from "../../components/withFlashMessages";

const TABLE_HEAD = [
  {
    head: "Título"
  },
  {
    head: "Subtítulo"
  }, {
    head: "Páginas"
  }, {
    head: "" // Ações
  },
];

function Chapters({ data, deleteModalInfo, startDeletingChapter, closeDeleteChapterModal }) {
  const { chapters } = data
  return (
    <section className="m-10">
      <Button size="md"
        className="flex items-center"
        variant="gradient"
        title="Cupons de desconto"
        onClick={() => window.location.href = '/discount_coupons'}
      >
        Cupoms de desconto <TicketIcon className="h-5 w-5 text-red ml-2" />
      </Button>
      <Card className="h-full w-full mt-5">
        <DeleteModal
          title="Deletar capítulo"
          method="delete"
          action={`/chapters/${deleteModalInfo.chapter?._id}`}
          message="Tem certeza que deseja deletar este capítulo?"
          open={deleteModalInfo.open}
          setOpen={() => closeDeleteChapterModal()} />

        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none flex flex-wrap gap-4 justify-between mb-4"
        >
          <div>
            <Typography variant="h6" color="blue-gray">
              Capítulos
            </Typography>
          </div>
          <div className="flex items-center w-full shrink-0 gap-4 md:w-max">
            <div className="w-full">
              <IconButton size="md"
                variant="gradient"
                title="Novo capítulo"
                onClick={() => window.location.href = '/chapters/new'}
              >
                <PlusIcon className="h-5 w-5 text-red" />
              </IconButton>
            </div>
          </div>

        </CardHeader>
        <CardBody className="overflow-scroll !px-0 py-2">
          <table className="w-full min-w-max table-auto">
            <thead>
              <tr>
                {TABLE_HEAD.map(({ head }) => (
                  <th
                    key={head}
                    className={`border-b border-gray-300 !p-4 pb-8 border-b border-gray-300 text-left`}
                  >
                    <Typography
                      color="blue-gray"
                      variant="small"
                      className="!font-bold"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {chapters.map(
                (
                  chapter,
                  index
                ) => {
                  const {
                    _id,
                    title,
                    subtitle,
                    pages
                  } = chapter;
                  const isLast = index === chapters.length - 1;
                  const classes = isLast
                    ? "!p-4"
                    : "!p-4 border-b border-gray-300";
                  return (
                    <tr key={index}>

                      <td className={classes}>
                        <Typography
                          variant="small"
                          className="!font-normal text-gray-600"
                        >
                          {title}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          className="!font-bold"
                        >
                          {subtitle}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          className="!font-bold"
                        >
                          <a href={`/chapters/${_id}/pages`}>
                            {pages}
                          </a >
                        </Typography>
                      </td>
                      <td className={classes}>
                        <div className="flex justify-end gap-4">
                          <IconButton
                            onClick={() => window.location.href = `/chapters/${_id}/edit`}
                            variant="text"
                            size="sm">
                            <PencilIcon className="h-5 w-5 text-red" />
                          </IconButton>
                          <IconButton onClick={() => startDeletingChapter(chapter)} variant="text" size="sm">
                            <XCircleIcon className="h-5 w-5 text-red" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </section>
  );
}

export default withFlashMessages(container(Chapters));