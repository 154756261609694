import { define } from 'remount'
import Login from './pages/Login'
import Chapters from './pages/Chapters'
import ChapterForm from './pages/ChapterForm'
import Pages from './pages/Pages'
import PageForm from './pages/PageForm'
import DiscountCoupons from './pages/DiscountCoupons'
import DiscountCouponForm from './pages/DiscountCouponForm'
import Sales from './pages/Sales'


// Chapters
define({ 'login-component': Login })
define({ 'chapters-component': Chapters })
define({ 'new-chapter-component': ChapterForm })

// Pages

define({ 'pages-component': Pages })
define({ 'new-page-component': PageForm })

// Discount coupon
define({ 'coupons-component': DiscountCoupons })
define({ 'new-coupon-component': DiscountCouponForm })

// Sales
define({ 'sales-component': Sales })
