import React from "react";
// @material-tailwind/react
import {
  Typography,
  Card,
  CardHeader,
  CardBody,
  Button,
  Input
} from "@material-tailwind/react";

function DiscountCouponForm({ coupon }) {

  return (
    <section className="m-10 h-screen">
      <Card className="w-full">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none flex flex-wrap gap-4 justify-between mb-4"
        >
          <div>
            <Typography variant="h6" color="blue-gray">
              {coupon ? `Editar cupom "${coupon.code}"` : 'Novo cupom'}
            </Typography>
          </div>
        </CardHeader>
        <CardBody className="h-full">
          <form method={coupon ? "put" : "post"}
            action={coupon ? `/discount_coupons/${coupon._id}` : '/discount_coupons'}>
            <div className="mb-6">
              <Input
                defaultValue={coupon ? coupon.code : ''}
                size="lg"
                name="discount_coupon[code]"
                variant="standard"
                placeholder="Código"
                label="Código"
                className="w-full"
              />
            </div>
            <div className="mb-6">
              <Input
                defaultValue={coupon ? coupon.price : ''}
                size="lg"
                name="discount_coupon[price]"
                variant="standard"
                type="number"
                step="0.01"
                placeholder="Preço"
                label="Preço"
                className="w-full"
              />
            </div>
            <div className="mb-6">
              <Input
                defaultValue={coupon ? coupon.appstore_product_id : ''}
                size="lg"
                name="discount_coupon[appstore_product_id]"
                variant="standard"
                placeholder="Id do produto na App Store"
                label="Id do produto na App Store"
                className="w-full"
              />
            </div>
            <div className="mb-6">
              <Input
                defaultValue={coupon ? coupon.playstore_product_id : ''}
                size="lg"
                name="discount_coupon[playstore_product_id]"
                variant="standard"
                placeholder="Id do produto na Google Play"
                label="Id do produto na Google Play"
                className="w-full"
              />
            </div>

            <Button type="submit">Salvar</Button>
          </form>
        </CardBody>
      </Card>
    </section>
  );
}

export default DiscountCouponForm