import React, { useEffect } from 'react'
import { useState } from "react";
import { allChapters } from '../../service/chapters.service';

export default (Children) => function () {

  const [data, setData] = useState({
    loadingChapters: true,
    chapters: []
  })

  const [deleteModalInfo, setDeleteModalInfo] = useState({ open: false, chapter: null })

  const getAllChapters = async () => {
    const result = await allChapters()
    setData(prevState => ({
      ...prevState,
      chapters: result.data,
      loadingChapters: false
    }))
  }

  const startDeletingChapter = (chapter) =>
    setDeleteModalInfo(prevState => ({
      ...prevState,
      open: true,
      chapter
    }))

  const closeDeleteChapterModal = () => setDeleteModalInfo(prevState => ({ ...prevState, open: false }))

  useEffect(() => {
  console.log('Chamou')
    getAllChapters()
  }, [])


  return (<Children
    startDeletingChapter={startDeletingChapter}
    closeDeleteChapterModal={closeDeleteChapterModal}
    data={data}
    deleteModalInfo={deleteModalInfo}
  />
  )
}