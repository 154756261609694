import React from "react";
// @material-tailwind/react
import {
  Typography,
  Card,
  CardHeader,
  CardBody,
  IconButton,
} from "@material-tailwind/react";

import {
  PlusIcon,
} from "@heroicons/react/24/solid";
import withFlashMessages from "../../components/withFlashMessages";

const TABLE_HEAD = [
  {
    head: "Cupom"
  },
  {
    head: "Data"
  }
];

function Sales({ sales }) {
  console.log(sales)


  return (
    <section className="m-10">
      <Card className="h-full w-full mt-5">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none flex flex-wrap gap-4 justify-between mb-4"
        >
          <div>
            <Typography variant="h6" color="blue-gray">
              Vendas do cupom {sales[0].discount_coupon._id}
            </Typography>
          </div>

        </CardHeader>
        <CardBody className="overflow-scroll !px-0 py-2">
          <table className="w-full min-w-max table-auto">
            <thead>
              <tr>
                {TABLE_HEAD.map(({ head }) => (
                  <th
                    key={head}
                    className={`border-b border-gray-300 !p-4 pb-8 border-b border-gray-300 text-left`}
                  >
                    <Typography
                      color="blue-gray"
                      variant="small"
                      className="!font-bold"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sales.map(
                (
                  sale,
                  index
                ) => {
                  const {
                    discount_coupon,
                    created_at,
                  } = sale;
                  const isLast = index === sales.length - 1;
                  const classes = isLast
                    ? "!p-4"
                    : "!p-4 border-b border-gray-300";
                  return (
                    <tr key={index}>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          className="!font-normal text-gray-600"
                        >
                          {discount_coupon.code}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          className="!font-normal text-gray-600"
                        >
                          {created_at}
                        </Typography>
                      </td>

                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </section>
  );
}

export default withFlashMessages(Sales);