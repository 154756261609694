import React, { useEffect } from 'react'
import { useState } from "react";
import { allPages, savePage } from '../../service/pages.service';
import axios from 'axios';

export default (Children) => function ({ chapterId, ...props }) {

  const [data, setData] = useState({
    loading: true,
    pages: []
  })

  const [deleteModalInfo, setDeleteModalInfo] = useState({ open: false, chapter: null })

  const getAllPagesForChapter = async () => {
    const result = await allPages(chapterId)
    setData(prevState => ({
      ...prevState,
      pages: result.data,
      loading: false
    }))
  }

  const startDeleting = (model) =>
    setDeleteModalInfo(prevState => ({
      ...prevState,
      open: true,
      model
    }))

  const closeDeleteModal = () => setDeleteModalInfo(prevState => ({ ...prevState, open: false }))

  const saveNewOrder = async (reorderedPages) => {
    console.log(reorderedPages)
    const response = await axios.all(reorderedPages.map((page, index) => {
      return savePage(chapterId, page._id, { position: index })
    }))

    console.log(response)
  }

  const moveItem = (dragPosition, hoverPosition) => {
    console.log(dragPosition)
    console.log(hoverPosition)
    const draggedItem = data.pages[dragPosition - 1];
    const newSortedPages = [...data.pages];
    console.log(newSortedPages)
    newSortedPages.splice(dragPosition - 1, 1);
    newSortedPages.splice(hoverPosition - 1, 0, draggedItem);
    setData(prevState => ({
      ...prevState,
      pages: newSortedPages,
      loading: false
    }))
    saveNewOrder(newSortedPages)
  };

  useEffect(() => {
    getAllPagesForChapter()
  }, [])


  return (<Children
    startDeleting={startDeleting}
    closeDeleteModal={closeDeleteModal}
    data={data}
    moveItem={moveItem}
    deleteModalInfo={deleteModalInfo}
    chapterId={chapterId}
    {...props}
  />
  )
}