import React, { useState } from "react";

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
// @material-tailwind/react
import {
  Typography,
  Card,
  CardHeader,
  CardBody,
  IconButton
} from "@material-tailwind/react";
import { useDrag, useDrop } from 'react-dnd';
import {
  Bars3Icon,
  PencilIcon,
  PlusIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import container from "./container";
import DeleteModal from "../../components/DeleteModal";
import withFlashMessages from "../../components/withFlashMessages";

const TABLE_HEAD = [
  {
    head: ""
  },
  {
    head: "Id"
  },
  {
    head: "Conteúdo da página"
  }, {
    head: "" // Ações
  },
];


function Pages({ data, chapterId, deleteModalInfo, startDeleting, closeDeleteModal, moveItem }) {
  const { pages } = data


  const TableRow = ({ page }) => {
    const [, drag] = useDrag({
      type: 'TableItem',
      item: { type: 'TableItem', position: page.position },

    });

    const [, drop] = useDrop({
      accept: 'TableItem',
      drop: (item) => {
        const dragIndex = item.position;
        const hoverIndex = page.position;

        if (dragIndex === hoverIndex) {
          return;
        }

        moveItem(dragIndex + 1, hoverIndex + 1);
        item.position = hoverIndex;
      },
    });

    const {
      _id,
      position,
      content
    } = page;
    const isLast = position === pages.length - 1;
    const classes = isLast
      ? "!p-4"
      : "!p-4 border-b border-gray-300";
    return <tr ref={(node) => drag(drop(node))}>
      <td className="border-b border-gray-300 w-1">
        <Bars3Icon className="size-6" />
      </td>
      <td className={classes}>
        <Typography
          variant="small"
          className="!font-bold"
        >
          {_id}
        </Typography>
      </td>

      <td className={classes}>
        <Typography
          variant="small"
          className="!font-bold"
        >
          {content.substring(0, 10)}
        </Typography>
      </td>

      <td className={classes}>
        <div className="flex justify-end gap-4">
          <IconButton
            onClick={() => window.location.href = `/chapters/${chapterId}/pages/${_id}/edit`}
            variant="text"
            size="sm">
            <PencilIcon className="h-5 w-5 text-red" />
          </IconButton>
          <IconButton onClick={() => startDeleting(page)} variant="text" size="sm">
            <XCircleIcon className="h-5 w-5 text-red" />
          </IconButton>
        </div>
      </td>
    </tr>
  }


  return (

    <section className="m-10">
      <Card className="h-full w-full">
        <DeleteModal
          title="Deletar página"
          method="delete"
          action={`/chapters/${chapterId}/pages/${deleteModalInfo.model?._id}`}
          message="Tem certeza que deseja deletar esta página?"
          open={deleteModalInfo.open}
          setOpen={() => closeDeleteModal()} />

        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none flex flex-wrap gap-4 justify-between mb-4"
        >
          <div>
            <Typography variant="h6" color="blue-gray">
              Páginas do capítulo #{chapterId}
            </Typography>
          </div>
          <div className="flex items-center w-full shrink-0 gap-4 md:w-max">
            <div className="w-full">
              <IconButton size="md"
                variant="gradient"
                title="Nova página"
                onClick={() => window.location.href = `/chapters/${chapterId}/pages/new`}
              >
                <PlusIcon className="h-5 w-5 text-red" />
              </IconButton>
            </div>
          </div>

        </CardHeader>
        <CardBody className="overflow-scroll !px-0 py-2">
          <table className="w-full min-w-max table-auto">
            <thead>
              <tr>
                {TABLE_HEAD.map(({ head }, index) => (
                  <th
                    key={index}
                    className={`border-b border-gray-300 !p-4 pb-8 border-b border-gray-300 text-left`}
                  >
                    <Typography
                      color="blue-gray"
                      variant="small"
                      className="!font-bold"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <DndProvider backend={HTML5Backend}>
                {pages.map((page, index) => <TableRow key={index} page={page} index={index} />)}
              </DndProvider>
            </tbody>
          </table>
        </CardBody>
      </Card>
    </section>

  );
}

export default withFlashMessages(container(Pages));