import axios from "axios"
const token =
    document.querySelector('[name=csrf-token]').content

axios.defaults.headers.common['X-CSRF-TOKEN'] = token
axios.defaults.headers.common["Accept"] = "application/json"
axios.defaults.headers.common["Content-Type"] = "application/json"

export const allChapters = async () => {
  return axios.get('/chapters.json')
}

export const saveChapter = async (body) => {
  return axios.post('/chapters', body)
}