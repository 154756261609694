import axios from "axios"
const token =
  document.querySelector('[name=csrf-token]').content

axios.defaults.headers.common['X-CSRF-TOKEN'] = token
axios.defaults.headers.common["Accept"] = "application/json"
axios.defaults.headers.common["Content-Type"] = "application/json"

export const allPages = async (chapterId) => {
  return axios.get(`/chapters/${chapterId}/pages.json`)
}

export const savePage = async (chapterId, pageId, body) => {
  return axios.put(`/chapters/${chapterId}/pages/${pageId}.json`, body)
}