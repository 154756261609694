import React from "react";
// @material-tailwind/react
import {
  Typography,
  Card,
  CardHeader,
  CardBody,
  Button,
  Input
} from "@material-tailwind/react";

function ChapterForm({ chapter }) {
  
  return (
    <section className="m-10 h-screen">
      <Card className="w-full">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none flex flex-wrap gap-4 justify-between mb-4"
        >
          <div>
            <Typography variant="h6" color="blue-gray">
              {chapter ? `Editar capítulo "${chapter.title}"` : 'Novo capítulo'}
            </Typography>
          </div>
        </CardHeader>
        <CardBody className="h-full">
          <form method={chapter ? "put" : "post"}
            action={chapter ? `/chapters/${chapter._id}` : '/chapters'}>
            <div className="mb-6">
              <Input
                defaultValue={chapter ? chapter.title : ''}
                size="lg"
                name="chapter[title]"
                variant="standard"
                placeholder="Título"
                label="Título"
                className="w-full"
              />
            </div>
            <div className="mb-6">
              <Input
                defaultValue={chapter ? chapter.subtitle : ''}
                size="lg"
                name="chapter[subtitle]"
                variant="standard"
                placeholder="Subtítulo"
                label="Subtítulo"
                className="w-full"
              />
            </div>

            <Button type="submit">Salvar</Button>
          </form>
        </CardBody>
      </Card>
    </section>
  );
}

export default ChapterForm