import React from "react";
import { useState } from "react";

import { Typography, Input, Button } from "@material-tailwind/react";
import { EyeSlashIcon, EyeIcon } from "@heroicons/react/24/solid";

export function Login({ actionUrl, errors }) {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => setPasswordShown((cur) => !cur);
  const token =
    document.querySelector('[name=csrf-token]').content

  return (
    <section className="grid text-center h-screen items-center p-8">
      <div>
        <Typography variant="h3" color="blue-gray" className="mb-2">
          AllBrain
        </Typography>
        <Typography className="mb-16 text-gray-600 font-normal text-[18px]">
          Entre seu email e senha para acessar
        </Typography>
        <form method="post" action={actionUrl} className="mx-auto max-w-[24rem] text-left">
          <input type="hidden" value="true" name="remember_me" ></input>
          <input type="hidden" value={token} name="authenticity_token" ></input>
          <div className="mb-6">
            <Input
              id="email"
              color="gray"
              variant="standard"
              size="lg"
              type="email"
              name="user[email]"
              label="Email"
              placeholder="nome@mail.com"
              className="w-full"
            />
          </div>
          <div className="mb-6">
            <Input
              size="lg"
              name="user[password]"
              variant="standard"
              placeholder="********"
              label="Senha"
              className="w-full"
              type={passwordShown ? "text" : "password"}
              icon={
                <i onClick={togglePasswordVisiblity}>
                  {passwordShown ? (
                    <EyeIcon className="h-5 w-5" />
                  ) : (
                    <EyeSlashIcon className="h-5 w-5" />
                  )}
                </i>
              }
            />
          </div>
          <Button color="gray" size="lg" className="mt-6" fullWidth type="submit">
            Entrar
          </Button>
        </form>
      </div>
    </section>
  );
}

export default Login;