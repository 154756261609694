import React, { useState } from "react";
// @material-tailwind/react
import {
  Typography,
  Card,
  CardHeader,
  CardBody,
  IconButton,
  Button,
  Switch
} from "@material-tailwind/react";

import {
  BookOpenIcon,
  PencilIcon,
  PlusIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import container from "./container";
import DeleteModal from "../../components/DeleteModal";
import withFlashMessages from "../../components/withFlashMessages";
import { updateCoupon } from "../../service/coupons.service";

const TABLE_HEAD = [
  {
    head: "Código"
  },
  {
    head: "Preço"
  }, {
    head: "Id do produto na App Store"
  }, {
    head: "Id do produto na Google Play"
  }, {
    head: "" // Ações
  },
];

function DiscountCoupons({ data, deleteModalInfo, startDeletingCoupon, closeDeleteCouponModal }) {
  const { coupons } = data

  const toggleCouponActive = async (id, checked) => {
    await updateCoupon(id, { active: checked })
  }
  return (
    <section className="m-10">
      <Button size="md"
        className="flex items-center"
        variant="gradient"
        title="Cupons de desconto"
        onClick={() => window.location.href = '/chapters'}
      >
        Capítulos <BookOpenIcon className="h-5 w-5 text-red ml-2" />
      </Button>
      <Card className="h-full w-full mt-5">
        <DeleteModal
          title="Deletar cupom"
          method="delete"
          action={`/discount_coupons/${deleteModalInfo.coupon?._id}`}
          message="Tem certeza que deseja deletar este cupom?"
          open={deleteModalInfo.open}
          setOpen={() => closeDeleteCouponModal()} />

        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none flex flex-wrap gap-4 justify-between mb-4"
        >
          <div>
            <Typography variant="h6" color="blue-gray">
              Cupons de desconto
            </Typography>
          </div>
          <div className="flex items-center w-full shrink-0 gap-4 md:w-max">
            <div className="w-full">
              <IconButton size="md"
                variant="gradient"
                title="Novo cupom"
                onClick={() => window.location.href = '/discount_coupons/new'}
              >
                <PlusIcon className="h-5 w-5 text-red" />
              </IconButton>
            </div>
          </div>

        </CardHeader>
        <CardBody className="overflow-scroll !px-0 py-2">
          <table className="w-full min-w-max table-auto">
            <thead>
              <tr>
                {TABLE_HEAD.map(({ head }) => (
                  <th
                    key={head}
                    className={`border-b border-gray-300 !p-4 pb-8 border-b border-gray-300 text-left`}
                  >
                    <Typography
                      color="blue-gray"
                      variant="small"
                      className="!font-bold"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {coupons.map(
                (
                  chapter,
                  index
                ) => {
                  const {
                    _id,
                    code,
                    price,
                    appstore_product_id,
                    playstore_product_id,
                    active
                  } = chapter;
                  const isLast = index === coupons.length - 1;
                  const classes = isLast
                    ? "!p-4"
                    : "!p-4 border-b border-gray-300";
                  return (
                    <tr key={index}>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          className="!font-normal text-gray-600"
                        >
                          {code}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          className="!font-bold"
                        >
                          {new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
                            price,
                          )}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          className="!font-bold"
                        >
                          {appstore_product_id}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          className="!font-bold"
                        >
                          {playstore_product_id}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <div className="flex justify-end gap-4">
                          <Switch
                            defaultChecked={active}
                            onChange={(e) => toggleCouponActive(_id, e.target.checked)}
                            ripple={true}
                          />

                          <IconButton
                            onClick={() => window.location.href = `/discount_coupons/${_id}/edit`}
                            variant="text"
                            size="sm">
                            <PencilIcon className="h-5 w-5 text-red" />
                          </IconButton>
                          <IconButton onClick={() => startDeletingCoupon(chapter)} variant="text" size="sm">
                            <XCircleIcon className="h-5 w-5 text-red" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </section>
  );
}

export default withFlashMessages(container(DiscountCoupons));