import React, { useEffect } from 'react'
import { useState } from "react";
import { allCoupons } from '../../service/coupons.service';

export default (Children) => function () {

  const [data, setData] = useState({
    loadingCoupons: true,
    coupons: []
  })

  const [deleteModalInfo, setDeleteModalInfo] = useState({ open: false, coupon: null })

  const getAllCoupons = async () => {
    const result = await allCoupons()
    setData(prevState => ({
      ...prevState,
      coupons: result.data,
      loadingCoupons: false
    }))
  }

  const startDeletingCoupon = (coupon) =>
    setDeleteModalInfo(prevState => ({
      ...prevState,
      open: true,
      coupon
    }))

  const closeDeleteCouponModal = () => setDeleteModalInfo(prevState => ({ ...prevState, open: false }))

  useEffect(() => {
    console.log('Chamou')
    getAllCoupons()
  }, [])


  return (<Children
    startDeletingCoupon={startDeletingCoupon}
    closeDeleteCouponModal={closeDeleteCouponModal}
    data={data}
    deleteModalInfo={deleteModalInfo}
  />
  )
}